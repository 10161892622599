<template>
    <div :style="{'z-index': popZindex}" class="popup-floating-layer-new">
        <div class="defaultDialogNew">
            <div :style="{width: popWidth + 'px'}" id="popup-assembly-new">
                <div class="popup-header-new">
                    <div class="popup-name-new"><slot class="popup-name" name="popup-name"></slot></div>
                    <div class="popup-tip-new" v-if="haveTips"><slot name="popup-tip"></slot></div>
                </div>
                <div :class="['popup-container-new',isNeedScroll ? 'needScroll' : '']">
                    <slot name="popup-con"></slot>
                </div>
                <div class="popup-container-c-new">
                    <slot name="popup-con-c"></slot>
                </div>
                <div class="add-automtated-closeBtn-new" @click="closeEvent">
                    <i class="arsenal_icon">&#xed63;</i>
                </div>
                <div  class="dialog-footer-new" v-if="isNeedFooter">
                    <slot name="dialog-footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "popup",
        props:{
            haveTips:{
                type:Boolean,
                default:true
            },
            isNeedFooter:{
                type:Boolean,
                default:true
            },
            isNeedScroll:{
                type:Boolean,
                default:true
            },
            popWidth:{
                type: Number,
                default: 646
            },
            popZindex:{
                type: Number,
                default: 2500
            },
        },
        data(){
            return{

            }
        },
        methods:{
            closeEvent(){
                this.$emit('closeEvent')
            }
        }
    }
</script>

<style scoped lang="less">
    @keyframes anim-open {
        0% { opacity: 0;}
        100% { opacity: 1;}
    }
    @keyframes anim-close {
        0% { opacity: 1; }
        100% { opacity: 0;}
    }
    .popup-floating-layer-new{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        margin: 0;
        background: rgba(0, 0, 0, 0.5) !important;
        // z-index: 2500;
        /*display: flex;
        align-items: center;
        justify-content: center;*/
        animation: dialog-fade-in 0.3s ease-in;
        .defaultDialogNew{
            position: relative;
            margin:  0 auto;
            margin-top:8vh;
        }
        #popup-assembly-new{
            background-color: white;
            border-radius: 5px;
            position: relative;
            margin:  0 auto;
            .popup-header-new{
                padding-top: 34px;
                padding-bottom: 34px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .popup-name-new>div{
                    font-size: 26px !important;

                }
                .popup-tip-new{
                    font-size: 14px;
                    color: #A9B3C6;
                    padding-top: 18px;
                }
            }
            .popup-container-new{
                max-height: calc(84vh - 200px);
                // min-height: 300px;
                overflow-y: hidden;
                padding: 0 64px;
               &.needScroll{
                    overflow-y: scroll;
               }

            }
            .popup-container-c-new{
                padding: 0 64px;
                .popup-con-c-new{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
            .add-automtated-closeBtn-new{
                width: 30px;
                height: 30px;
                background: #FFFFFF;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.21);
                border-radius: 50%;
                position: absolute;
                right: -8px;
                top: -10px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                i{
                    font-size: 18px;
                    color: #616161;
                }
            }
            .dialog-footer-new{
                padding-bottom: 30px;
                padding-top: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                .el-button {
                    min-width: 90px;
                }
                .cancel-btn{
                    height: 30px;
                    line-height: 30px;
                    width: 99px;
                    background: #FFFFFF;
                    border: 1px solid #E0E6F7;
                    padding: 0;
                    border-radius: 50%;
                }
                .confirm-btn{
                    height: 30px;
                    width: 99px;
                    background: #366AFF;
                    border: 2px solid #366AFF;
                    padding: 0;
                    line-height: 26px;
                    border-radius: 50%;
                    &.is-loading{
                        border-color: #7aa3ff;
                    }
                }
                .el-button--default {
                    // height: 37px !important;
                    border-radius: 17px;
                }
                .el-button--default:hover{
                    border-color: #366AFF;
                    color: #366AFF;
                }
                .el-button--primary{
                    // height: 37px !important;
                    border-radius: 17px;
                    background-color: #366AFF;
                }
                .delete-footer{
                    position: absolute;
                    left: 64px;
                    .delete-btn{
                        width: 28px;
                        height: 28px;
                        background: #FFFFFF;
                        border: 1px solid #E0E6F7;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                    }
                    
                }
            }
        }
    }

</style>