<template>
    <div class="healthy-score">
        <template v-if="type && type === 'intent'">
            <div class="perfect-intent" v-if="score === 100">
                <img src="../../../assets/images/perfect-intent.png" alt="">
                <span class="perfect-score-num score-num">{{score}}</span>
                <div class="right-text">
                    <span>健康度非常完美，但也要不断优化哦</span>
                    <span class="tips">持续的优化会让机器人能力越来越强</span>
                </div>
            </div>
            <div class="better-intent" v-if="94 < score && score < 100">
                <img src="../../../assets/images/better-intent.png" alt="">
                <span class="better-score-num score-num">{{score}}</span>
                <div class="right-text">
                    <span>健康度堪称完美，请但也要不断优化哦</span>
                    <span class="tips">持续的优化会让机器人能力越来越强</span>
                </div>
            </div>
            <div class="better-intent" v-if="79 < score && score < 95">
                <img src="../../../assets/images/normal-intent.png" alt="">
                <span class="normal-score-num score-num">{{score}}</span>
                <div class="right-text">
                    <span>健康度正常，还有更多优化空间哦</span>
                    <span class="tips">不断提高健康度会提高机器人的体验</span>
                </div>
            </div>
            <div class="better-intent" v-if="60 < score && score < 80">
                <img src="../../../assets/images/lower-intent.png" alt="">
                <span class="lower-score-num score-num">{{score}}</span>
                <div class="right-text">
                    <span>健康度偏低，请尽快优化</span>
                    <span class="tips">一系列问题会严重影响机器人正常使用</span>
                </div>
            </div>
            <div class="better-intent" v-if="61> score">
                <img src="../../../assets/images/last-lower-intent.png" alt="">
                <span :class="['last-lower-score-num score-num',score < 10 ? 'little' : '']">{{score}}</span>
                <div class="right-text">
                    <span>健康度过低，请尽快优化</span>
                    <span class="tips">一系列问题会严重影响机器人正常使用</span>
                </div>
            </div>
            
        </template>
        <template v-else>
            <div class="perfect" v-if="score === 100">
                <img src="../../../assets/images/perfect.png" alt="">
                <span class="perfect-score-num score-num">{{score}}</span>
                <span class="text">健康度非常完美</span>
            </div>
            <div class="better" v-if="94 < score && score < 100">
                <img src="../../../assets/images/better.png" alt="">
                <span class="better-score-num score-num">{{score}}</span>
                <span class="text">健康度堪称完美</span>
            </div>
            <div class="better" v-if="79 < score && score < 95">
                <img src="../../../assets/images/normal.png" alt="">
                <span class="normal-score-num score-num">{{score}}</span>
                <span class="text">健康度正常</span>
            </div>
            <div class="better" v-if="60 < score && score < 80">
                <img src="../../../assets/images/lower.png" alt="">
                <span class="lower-score-num score-num">{{score}}</span>
                <span class="text">健康度偏低</span>
            </div>
            <div class="better" v-if="61> score">
                <img src="../../../assets/images/last-lower.png" alt="">
                <span :class="['last-lower-score-num score-num',score < 10 ? 'little' : '']">{{score}}</span>
                <span class="text">健康度过低</span>
            </div>
        </template>
        
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    props:{
        score:{
            type:Number,
            default:1
        },
        type:{
            type:String,
            default:''
        }
    }
}
</script>

<style scoped lang="less">
.healthy-score{
    .perfect,.better{
        position: relative;
        text-align: left;
        display: flex;
        height: 28px;
        align-items: center;
        img{
            width: 28px;
            height: 28px;
        }
        .score-num{
            font-size: 10px; transform: scale(0.83333); transform-origin: 0 0;
            position: absolute;
            top: 9px;
            position: absolute;
            display: inline-block;
            width: 13px;
            height: 13px;
            text-align: center;
            line-height: 13px;
        }
        .perfect-score-num{
            color: #54BBFF;
            left: 5px;
        }
        .better-score-num{
            color: #77E427;
            left: 8px;
        }
        .normal-score-num{
            color: #39DBAF;
            left: 8px;
        }
        .lower-score-num{
            color: #FF9C00;
            left: 8px; 
        }
        .last-lower-score-num{
            color: #FF5151;
            left: 8px; 
            &.little{
                // left: 11px;
            }
        }
        .text{
            color: #616161;
            font-size: 14px;
            margin-left: 8px;
        }
        
    }
    .perfect-intent,.better-intent{
        position: relative;
        text-align: left;
        display: flex;
        height: 39px;
        align-items: center;
        img{
            width: 39px;
            height: 39px;
        }
        .score-num{
            font-size: 14px;
            position: absolute;
            top: 12px;
            display: inline-block;
            width: 16px;
            height: 16px;
            text-align: center;
            line-height: 16px;
        }
        .perfect-score-num{
            color: #54BBFF;
            left: 7px;
        }
        .better-score-num{
            color: #77E427;
            left: 12px;
        }
        .normal-score-num{
            color: #39DBAF;
            left: 12px;
        }
        .lower-score-num{
            color: #FF9C00;
            left: 12px;
        }
        .last-lower-score-num{
            color: #FF5151;
            left: 12px;
            &.little{
                // left: 16px;
            }
        }
        .right-text{
            display: flex;
            flex-direction: column;
            font-size: 15px;
            color: #000;
            margin-left: 10px;
            .tips{
                color: #A9B3C6;
                font-size: 12px;
            }
        }
    }
}

</style>