<template>
    <div>
        <popup @closeEvent="closeEditBotSt" v-if="editBotStVisible" :isNeedScroll="false">
            <div slot="popup-name">编辑机器人顺序</div>
            <div slot="popup-tip">拖拽可改变文件顺序</div>
            <div slot="popup-con" class="popup-con">
                 <div id="bot-order">
                    <div class="self-skill">
                        <div
                            :data-id="JSON.stringify(item)"
                            v-for="(item,index) in botLists"
                            :key="item.id"
                            class="self-skill-cell">
                            <!-- <span class="order-number">{{ item.botOrder }}</span> -->
                            <span class="order-number">{{ index+1 }}</span>
                            <div class="skill-cell-content">
                                <div class="skill-cell-content-name">
                                    <span v-if="!item.isEdit">{{ item.name }}</span>
                                    <el-input
                                        v-if="item.isEdit"
                                        size="mini"
                                        v-model="item.name"
                                        maxlength="15"
                                    ></el-input>
                                </div>
                                <div class="skill-cell-handle">
                                    <i @mouseenter="resortOptions" class="iconfont guoran-a-16-10"></i>
                                    <i @mouseenter="resortOptions" class="iconfont guoran-tongyichicun-16-16-paixuhover"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEditBotSt" round class="cancel-btn">取消</el-button>
                <el-button type="primary"   @click="saveEditBotSt"  round class="confirm-btn">保存</el-button>
            </div>
        </popup>
    </div>
</template>
<script>
import Popup from "../../../components/popupNew.vue";
import Sortable from "sortablejs";
export default {
    components:{
        Popup
    },
    data() {
        return {
            botLists: [],
        };
    },
    props:{
       
        editBotStVisible:{
            type:Boolean,
            default:false
        }
    },
   
    methods: {
         /**
         * 根据botId获取我的知识库列表
         * @method getBotList
         * @param {}
         * @return {}
         */
        getBotList() {
            //获取机器人列表
            this.FetchGet(this.requestUrl.bot.getBotList, {
                page: 1,
                size: 100,
            }).then((res) => {
                if (res.code === "0") {
                    if (res.data.list != null) {
                        res.data.list.forEach((item, index) => {
                            item.isEdit = false;
                            // item.orderNum = index + 1;
                        });
                    }
                    this.botLists = res.data.list;
                }
            });
        },
        closeEditBotSt(){
            this.$emit('closeEditBotSt');
        },
        saveEditBotSt(){
            this.$emit('saveEditBotSt', this.botLists);
        },
        //选项排序
        resortOptions(type){
            let el = document.querySelector(".self-skill");
            let sortables = new Sortable(el, {
                animation: 200,
                easing: "cubic-bezier(1, 0, 0, 1)",
                handle: '.iconfont',
                forceFallback: false,  
                onEnd:()=>{
                    let arr = sortables.toArray()
                    let testArr = []
                    testArr = arr.length > 0 ? arr.map((item,index) => {
                        return JSON.parse(item)
                    }) : []
                    this.botLists = [];
                    testArr.forEach((item,index) => {
                        // item.botOrder = index+1;
                        this.$set(this.botLists,index,item)
                    })
                }
            });
        },
    },
};
</script>
<style lang="less" scoped>
#bot-order {
     max-height: calc(84vh - 200px);
    min-height: 300px;
    overflow-y: scroll;
    .dialog-footer {
        display: block;
        margin-top: 24px;
        text-align: center;
        margin-top: 30px;
        .el-button{
            width: 99px;
            height: 37px;
            border-radius: 19px;
            border-color: #E0E6F7;
            padding: 0;
            text-align: center;
            font-size: 14px;
            &.el-button--primary{
                border-color: #366AFF;
                background-color: #366AFF;
            }

        }
    }
    .self-skill {
        // max-height: 500px !important;
        // overflow-y: auto;
        .self-skill-cell {
            height: 41px;
            line-height: 42px;
            display: flex;
            justify-content: flex-start;
            border-top:  1px solid #E0E6F7;
            align-items: center;
            color: #616161;
            font-size: 14px;
            cursor: pointer;
            padding: 0 10px;
            &.active{
                background-color: #F6F8FD;
                .order-number{
                    background-color: #366AFF;
                    color: #fff;
                }
            }
            .iconfont{
                color: #A9B3C6;
                font-size: 16px;
            }
            .order-number {
                text-align: center;
                height: 16px;
                // width: 16px;
                padding: 0 5px;
                background: #FFFFFF;
                border: 1px solid #366AFF;
                border-radius: 5px;
                line-height: 18px;
                background-color: #fff;
                color: #366AFF;
                margin-right: 35px;
                font-size: 12px;
            }
            .skill-cell-content {
                flex: auto;
                display: flex;
                justify-content: space-between;
                .skill-cell-content-name {
                    flex: auto;
                    overflow: hidden;
                    text-align: left;
                    .el-input {
                        width: 100% !important;
                    }
                }
                .skill-cell-handle {
                    flex: none;
                    width: 70px;
                    color: #366AFF;
                    font-size: 14px;
                    letter-spacing: 4px;
                    text-align: right;
                    .guoran-a-16-10{
                        display: inline-block;
                        color: #A9B3C6;
                    }
                    .guoran-tongyichicun-16-16-paixuhover{
                        display: none;
                    }
                }
                .skill-cell-handle-join {
                    // display: none;
                    flex: none;
                    width: 120px;
                }
            }
            &:hover{
                background-color: #F6F8FD;
                .order-number {
                    background-color: #366AFF;
                    color: #fff;
                }
                .guoran-a-16-10{
                    display: none !important;
                }
                .guoran-tongyichicun-16-16-paixuhover{
                    display: inline-block !important;
                    color:#366AFF ;
                }
            }
           
        }
    }
}
</style>