import { render, staticRenderFns } from "./healthyScore.vue?vue&type=template&id=f508668e&scoped=true&"
import script from "./healthyScore.vue?vue&type=script&lang=js&"
export * from "./healthyScore.vue?vue&type=script&lang=js&"
import style0 from "./healthyScore.vue?vue&type=style&index=0&id=f508668e&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f508668e",
  null
  
)

export default component.exports