<template>
    <div id="botIndex">
        <page-top-header>
            <div slot="top-header-left" class="top-header-left">
                <i class="iconfont guoran-tongyichicun-jiqirentouxiang"></i>
                <span class="page-name">全部机器人</span>
            </div>
        </page-top-header>
        <div class="bot-content">
            <div class="myBots">
               <div class="botIndex-top-operation">
                    <div class="title">
                        <span class="title-text">我的机器人</span>
                    </div>
                    <div class="edit-sort" @click="editBotSt">
                        <span class="iconfont guoran-a-16-10"></span>
                        <span class="edit-bot-d">编辑机器人顺序</span>
                    </div>
               </div>
                <ul class="bots" ref="botListContent" v-loading="loading">
                    <li
                        v-if="RP_Visible('OPEN_BOT_ADD')"
                        ref="upAddBot"
                        :class="['addBot', 'bot', 'cursor', limitUse?'botLimitUse':'']"
                        @click="addBot(true)"
                        id="addBot"
                    >
                        <img src="../../assets/images/addBot.png" alt="" />
                        <span class="addBotText"
                            ><i class="iconfont guoran-a-16-13"></i
                            >创建机器人</span
                        >
                    </li>
                    <li
                        class="bot cursor"
                        v-for="(item, index) in botList"
                        :key="item.id"
                        @click.stop="
                            gotoBotIntent(
                                item.id,
                                item.name,
                                item.refApiKey,
                                'cursor',
                                item
                            )
                        "
                    >
                        <div class="botConfig" @click.stop="">
                            <el-dropdown
                                @command="botHandleCommand($event,item)"
                                trigger="click"
                            >
                                <span
                                    v-if="
                                        RP_Visible('OPEN_BOT_UPDATE') ||
                                        RP_Visible('OPEN_BOT_DELETE')
                                    "
                                    class="el-dropdown-link"
                                >
                                    <em class="el-icon-more cursor"></em>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item
                                        v-if="RP_Visible('OPEN_BOT_UPDATE')"
                                        :command="{ type: 1, index: index }"
                                        >编辑</el-dropdown-item
                                    >
                                    <el-dropdown-item
                                        v-if="RP_Visible('OPEN_BOT_DELETE')"
                                        :command="{ type: 2, index: index }"
                                        divided
                                        >删除</el-dropdown-item
                                    >
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <span class="cursor">
                            <el-image
                                class="botImage cursor"
                                :src="item.botProfilePhoto"
                                :fit="'cover'"
                            >
                            </el-image>
                        </span>
                        <span class="botName cursor">{{ item.name }}</span>
                        <div
                            class="botDes cursor"
                        >
                            {{ item.description }}
                        </div>
                        <div class="healthy-box">
                            <healthyScore :score="item.lastCheckDetails&&item.lastCheckDetails&&item.lastCheckDetails.totalScore?item.lastCheckDetails.totalScore:0"></healthyScore>
                            <div class="now-optimize" @click.stop="nowOptimize(item)">立即优化</div>
                        </div>
                    </li>
                    <li v-for="(emptyCell, index) in needAddNumArr" :key="'emptyCell_' + index" class="bot bot_empty"></li>
                </ul>
            </div>
            <div class="divider-line"></div>
            <div
                class="templateBots"
                v-show="
                    RP_Visible('OPEN_BOT_ADD') &&
                    RP_Visible('OPEN_SKILL_UPDATE')
                "
            >
                <!-- <em class="arsenal_icon arsenaldengpao"></em> -->
                <div class="switch-tab-box">
                   <div class="left-switch-tab-box">
                        <div
                            :class="[
                                'tab-item',
                                kbbActiveName === 'official' ? 'active' : '',
                            ]"
                            @click="switchTab('official')"
                        >
                            官方知识库模板
                        </div>
                        <div
                            :class="[
                                'tab-item',
                                kbbActiveName === 'private' ? 'active' : '',
                            ]"
                            @click="switchTab('private')"
                        >
                            我的知识库模板
                        </div>
                   </div>
                     <div v-if="kbbActiveName === 'private'" @click="managePrivate" class="tab-item manage-private-bot">
                        <i class="iconfont guoran-tongyichicun-guanliwodezhishikumoban"></i>
                        <span class="manage-private">管理我的知识库模板</span>
                    </div>
                </div>
                <ul
                    class="bots-s"
                    ref="bots_s"
                    v-loading="loading"
                    v-show="kbbActiveName === 'official'"
                >
                    <li
                        v-for="(item, index) in pubSkillList"
                        :key="index"
                        ref="firstPubSkill"
                        class="bot-s"
                    >
                        <span class="botName">{{ item.name }}</span>
                        <div class="usebot">
                            <div
                                plain
                                @click="
                                    selectUseTemplateType({
                                        apiKey: item.apiKey,
                                        skillId: item.skillId,
                                        name: item.name,
                                        description: item.description,
                                    })
                                "
                            >
                                使用该知识库
                            </div>
                            <img
                                src="../../assets/images/bgc.png"
                                alt=""
                                class="bgc-img"
                            />
                        </div>
                    </li>
                    <li class="bot-s" style="opacity: 0" v-for="(eCell, eCellIndex) in needAddNumArr_s" :key="eCellIndex"></li>
                </ul>
                <ul
                    class="bots-p"
                    ref="bots_p"
                    v-loading="loading"
                    v-show="kbbActiveName === 'private'"
                >
                    <li
                        v-for="(item, index) in userTemplates"
                        :key="index"
                        ref="firstSelfSkill"
                        class="bot-s"
                    >
                        <span class="botName">{{ item.name }}</span>
                        <div class="botDes">
                            <span>{{ item.description }}</span>
                        </div>
                        <!-- <div class="usebot">
                            <el-button
                                size="small"
                                type="primary"
                                plain
                                @click="
                                    selectUseTemplateType({
                                        apiKey: item.apiKey,
                                        skillId: item.skillId,
                                        name: item.name,
                                        description: item.description,
                                    })
                                "
                                >使用该知识库</el-button
                            >
                        </div> -->
                        <div class="usebot">
                            <div
                                plain
                                @click="
                                    selectUseTemplateType({
                                        apiKey: item.apiKey,
                                        skillId: item.skillId,
                                        name: item.name,
                                        description: item.description,
                                    })
                                "
                            >
                                使用该知识库
                            </div>
                            <img
                                src="../../assets/images/bgc.png"
                                alt=""
                                class="bgc-img"
                            />
                        </div>
                    </li>
                    <li class="bot-p" style="opacity: 0" v-for="(eCell, eCellIndex) in needAddNumArr_p" :key="eCellIndex"></li>
                    <!-- <li @click="managePrivate" class="manage-private-bot">
                        <span class="el-icon-s-tools"></span>
                        <span class="manage-private">管理我的知识库模板</span>
                    </li> -->
                </ul>
            </div>
        </div>
        <popup
            @closeEvent="dialogVisible = false"
            v-if="dialogVisible"
            class="add-bot-popup"
        >
            <div slot="popup-name">{{ activeBotDiaHandleTitle.diaTitle }}</div>
            <div slot="popup-tip">输入详细信息创建机器人</div>
            <div slot="popup-con" class="popup-con">
                <el-form ref="form" label-width="50px" :label-position="'top'">
                    <el-form-item label="名称">
                        <el-input
                            v-model="botName"
                            show-word-limit
                            maxlength="15"
                            placeholder="请输入机器人名称"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="简介">
                        <el-input
                            v-model="botDes"
                            type="textarea"
                            maxlength="200"
                            show-word-limit
                            placeholder="请输入机器人简介"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="图标">
                        <div class="icon-list">
                            <div
                                @click.stop="chooseBotIcon(item, index)"
                                :class="[
                                    'item',
                                    BotActiveObj.botProfilePhoto === item.botProfilePhoto ? 'active' : '',
                                ]"
                                v-for="(item, index) in iconList"
                                :key="item.id"
                            >
                               <div v-if="item.theDefault" class="default-img">
                                    <img
                                        :src="item.botProfilePhoto"
                                        alt=""
                                        
                                    />
                                    <div class="el-icon-check" v-if="BotActiveObj.botProfilePhoto === item.botProfilePhoto"></div>
                               </div>
                              
                                <div class="icon-handler-box" v-else>
                                    <el-image
                                        style="
                                            width: 70px;
                                            height: 70px;
                                            border-radius: 50%;
                                        "
                                        :src="item.botProfilePhoto"
                                        :fit="'cover'"
                                    >
                                    </el-image>
                                    <div
                                        class="del-bot-icon"
                                        @click.stop="delBotIcon(item)"
                                    >
                                        <i class="guoran-shanchu iconfont "></i>
                                    </div>
                                    <div class="el-icon-check" v-if="BotActiveObj.botProfilePhoto === item.botProfilePhoto"></div>
                                </div>
                            </div>
                            <div class="upload-content">
                                <el-upload
                                    class="avatar-uploader"
                                    :action="requestUrl.webhook.uploadFile"
                                    :show-file-list="false"
                                    :on-success="handleAvatarSuccess"
                                    :before-upload="beforeAvatarUpload"
                                >
                                    <i
                                        class="el-icon-plus avatar-uploader-icon"
                                    ></i>
                                </el-upload>
                                <!-- <div class="el-upload__tip">上传头像</div> -->
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item class="no-bottom">
                        <el-checkbox v-model="botNeedChatSkill"
                            >创建寒暄库</el-checkbox
                        >
                    </el-form-item>
                </el-form>
            </div>
            <div slot="dialog-footer">
                <el-button
                    @click="dialogVisible = false"
                    plain
                    class="cancel-btn"
                    round
                    >取消</el-button
                >
                <el-button
                    type="primary"
                    @click="submitAddBot(activeBotDiaHandleTitle.type)"
                    round
                    class="confirm-btn"
                    >{{ activeBotDiaHandleTitle.btnTitle }}</el-button
                >
            </div>
        </popup>

        <!-- 使用机器人模板 -->
        <popup
            @closeEvent="useTemplateDialogVisible = false"
            v-if="useTemplateDialogVisible"
            :haveTips="false"
        >
            <div slot="popup-name">使用机器人模板</div>
            <div slot="popup-con" class="popup-con">
                <div class="use-bot-template-content">
                    <div class="title">是否将模板添加到已有机器人？</div>
                    <el-radio-group v-model="isAddExit">
                        <el-radio :label="false">否，新创建一个机器人</el-radio>
                        <el-radio :label="true">是，添加到已有机器人</el-radio>
                    </el-radio-group>
                    <div v-if="isAddExit" class="select-title">
                        请选择添加到哪个机器人
                    </div>
                    <div v-if="isAddExit">
                        <el-select
                            v-model="isAddBotId"
                            placeholder="请选择"
                            ref="botList"
                        >
                            <el-option
                                @click.native="chooseBot(item, index)"
                                v-for="(item, index) in botList"
                                :disabled="item.used"
                                :class="[item.used ? 'used' : '']"
                                :key="item.apiKey"
                                :label="item.name"
                                :value="item.apiKey"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button
                    @click="useTemplateDialogVisible = false"
                    plain
                    class="cancel-btn"
                    round
                    >取消</el-button
                >
                <el-button
                    type="primary"
                    @click="confirmUseTemplate"
                    round
                    class="confirm-btn"
                    >确认</el-button
                >
            </div>
        </popup>
        <!-- 指引 -->
        <el-dialog
            :visible.sync="centerDialogVisible"
            :close-on-click-modal="false"
            :show-close="false"
            width="30%"
            center
        >
            <div class="step-container">
                <el-image :src="guideImageUrl"></el-image>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button
                    v-show="guideImageClickNum > 1"
                    @click="firstGuideBack"
                    type="primary"
                    >上一步</el-button
                >
                <el-button type="primary" @click="firstGuideNext"
                    >下一步</el-button
                >
            </span>
            <span class="skip-button" @click="handleClose">跳过</span>
        </el-dialog>
        <!-- 管理知识库模板 -->
        <popup
            @closeEvent="manageKNPVisible = false"
            v-if="manageKNPVisible"
            :haveTips="false"
            :isNeedScroll="false"
            class="manage-know-template"
        >
            <div slot="popup-name">管理知识库模板</div>
            <div slot="popup-con" class="popup-con">
                <div class="manageknp-content">
                    <div class="knTreeBox">
                        <span class="knTreeTitle"
                            >从现有知识库中选择知识库作为模板</span
                        >
                        <div class="scroll-box">
                            <div class="knTree">
                                <div
                                    v-for="(bot, botIndex) in botKnList"
                                    :key="botIndex"
                                    class="knTreeBot"
                                >
                                    <div
                                        v-if="
                                            bot.children &&
                                            bot.children.length != 0 &&
                                            !bot.checkedAll
                                        "
                                        class="knTreeBotInfo"
                                    >
                                        <span
                                            @click="
                                                changeExpend(botIndex, false)
                                            "
                                            v-show="bot.isExpend"
                                            class="tree-pre-icon el-icon-arrow-down"
                                        ></span>
                                        <span
                                            @click="
                                                changeExpend(botIndex, true)
                                            "
                                            v-show="!bot.isExpend"
                                            class="tree-pre-icon el-icon-arrow-right"
                                        ></span>
                                        <el-checkbox
                                            :disabled="bot.botIsDisabled"
                                            @change="checkedAll(botIndex)"
                                            v-model="bot.checkedAll"
                                        >
                                            <span
                                                class="tree-pre-bot-icon iconfont guoran-jiqirentouxiang"
                                            ></span>
                                            {{ bot.name }}</el-checkbox
                                        >
                                    </div>
                                    <ul
                                        v-show="bot.isExpend && !bot.checkedAll"
                                        class="knTreeKnInfo"
                                    >
                                        <li
                                            v-for="(
                                                knCell, knCellIndex
                                            ) in bot.children"
                                            v-show="
                                                !knCell.isChecked &&
                                                knCell.name != '特殊规则'
                                            "
                                            :key="knCellIndex"
                                        >
                                            <el-checkbox
                                                :disabled="
                                                    knCell.isTemplateCreate
                                                "
                                                @change="
                                                    checkedSing(
                                                        botIndex,
                                                        knCellIndex,bot,knCell
                                                    )
                                                "
                                                v-model="knCell.isChecked"
                                            >
                                                <span
                                                    class="tree-pre-bot-icon el-icon-notebook-2"
                                                ></span>
                                                {{ knCell.name }}</el-checkbox
                                            >
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="scroll-box">
                        <div class="selectKnListBox">
                            <span class="knTreeTitle">我的知识库模板</span>
                            <ul class="selectKnList">
                                <li
                                    v-for="(
                                        knCell, knCellIndex
                                    ) in checkedKnInfos"
                                    :key="knCellIndex"
                                    class="knCell"
                                >
                                    <span
                                        class="icon-notebook el-icon-notebook-2"
                                    ></span>
                                    <span class="kn-name"
                                        >{{ knCell.botName }}/{{
                                            knCell.skillName
                                        }}</span
                                    >
                                    <span
                                        @click="closeKn(knCell, knCellIndex)"
                                        class="guoran-tongyichicun-16-09-shanchu2 iconfont"
                                    ></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <div class="no-c-tips">
                    使用了知识库模板的知识库已置灰，不可再设置为我的知识库模板。
                </div>
                <el-button
                    @click="manageKNPVisible = false"
                    plain
                    class="cancel-btn"
                    round
                    >取消</el-button
                >
                <el-button
                    type="primary"
                    @click="submitKnP"
                    round
                    class="confirm-btn"
                    >确认</el-button
                >
            </div>
        </popup>
        <edit-bot-st
            :editBotStVisible="editBotStVisible"
            @closeEditBotSt="closeEditBotSt"
            @saveEditBotSt="saveEditBotSt"
            ref="editBotSt"
        ></edit-bot-st>
         <!-- 版本功能不可用提示 -->
        <popup v-if="versionTipDialog" @closeEvent="versionTipDialog = false">
            <div slot="popup-name" class="popup-name">无法访问</div>
            <div slot="popup-tip">您当前的版本无法使用该功能</div>
            <div slot="popup-con">
                <VersionTip :tipIndex="tipIndex"/>
            </div>
            <div slot="dialog-footer">
                <el-button size="small" plain @click="versionTipDialog = false"
                    >我知道了</el-button
                >
                <el-button
                    type="primary"
                    size="small"
                    @click="versionTipDialog = false"
                    >拨打 4001-789-800 升级</el-button
                >
            </div>
        </popup>
    </div>
</template>
<script>
import Qs from "qs";
import Popup from "../../components/popup";

import pageTopHeader from "../../components/page-top-header.vue";
import Driver from "driver.js"; // import driver.js
import "driver.js/dist/driver.min.css"; // import driver.js css
import steps from "./guide/BotIndex";
import { apiKeys, skillIds } from "../../const/SpecialApiKey.js";
import EditBotSt from "./component/BotOrder.vue";
import { getFileSuffix } from "../../utils/AliyunlssUtils";
import editBotSt from "./component/BotOrder.vue";
import VersionTip from "./../version/VersionTip.vue";
import elementResizeDetectorMaker from "element-resize-detector";
import healthyScore from './component/healthyScore.vue'
let botDiaHandleTitle = {
    edit: {
        diaTitle: "编辑机器人",
        btnTitle: "确认",
        type: 1,
    },
    add: {
        diaTitle: "创建机器人",
        btnTitle: "创建",
        type: 2,
    },
};
export default {
    name: "botIndex",
    components: { EditBotSt, pageTopHeader, Popup, editBotSt,VersionTip,healthyScore },
    data() {
        return {
            needAddNum: 0,
            needAddNumArr: [],
            listLen: 0,

            needAddNum_s: 0,
            needAddNumArr_s: [],
            listLen_s: 0,

            needAddNum_p: 0,
            needAddNumArr_p: [],
            listLen_p: 0,

            versionTipDialog: false,
            limitUse: false,
            tipIndex: 3,
            paramBotList: [],
            editBotStVisible: false,
            botKnList: [],
            checkedKnInfos: [],
            manageKNPVisible: false,
            kbbActiveName: "official",
            botIconIndex: 0,
            isAddExit: true,
            isAddBotId: "",
            useTemplateDialogVisible: false,
            templateInfo: {},
            activePageInfo: {
                headerName: "全部机器人",
            },
            activeBotDiaHandleTitle: {},
            dialogVisible: false,
            botName: "",
            botDes: "",
            botNeedChatSkill: true, //创建机器人时是否需要补充闲聊库
            isAdd: false,
            activeEditId: "",
            botList: [],
            loading: true,
            driver: null,
            //示例机器人
            pubSkillList: [],
            userTemplates: [],
            newBotId: "",
            newBotName: "",
            accountType: "NORMAL",
            loginSuccessTimes: "",
            centerDialogVisible: false,
            guideImageUrl:
                "https://guoranbot-static.oss-cn-beijing.aliyuncs.com/image/startup1.png",
            guideImageClickNum: 1,
            iconList: [],
            BotActiveObj:{},
            isShowTest: false,
            CompanyIsOld:true,
            isShowHeathly:false
        };
    },
    methods: {
         // 判断企业是不是旧客
        getCompanyIsOld(){
            this.FetchGet(this.requestUrl.botHealthCheck.getCompanyIsOld).then(res => {
                if (res.code === "0") {
                  this.CompanyIsOld = res.data;
                  sessionStorage.setItem('CompanyIsOld',this.CompanyIsOld)
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 立即优化
        nowOptimize(item){
            console.log(item,'item');
            this.$router.push({
                name: "botIntent",
                query: {
                    id: item.id,
                    name: item.name.trim(),
                    refApiKey: item.refApiKey,
                    nowOptimize:1
                },
            });
        },
        // 待封装优化代码段 liangpengyu
        watchSize() {
            let _this = this;
            let erd = elementResizeDetectorMaker();
            erd.listenTo(_this.$refs.botListContent, (element) => {
                _this.$nextTick(() => {
                    if (_this.listLen !== 0) {
                        _this.addEmptyEl(_this.listLen);
                    }
                })
            });
        },
        // 待封装优化代码段 liangpengyu
        addEmptyEl(listLen){
            if (this.$refs.botListContent != undefined) {
                let outerW = this.$refs.botListContent.offsetWidth; // 容器宽度
                let cellW = this.$refs.upAddBot.offsetWidth; // 每个cell宽度
                let evNum = Math.floor(outerW/cellW); // 每行个数
                let needAddNum = (evNum - ((listLen  + 1)%evNum)) === evNum?0:(evNum - ((listLen  + 1)%evNum)); // 最后一行需要补空意图数
                this.needAddNum = needAddNum;
                this.needAddNumArr = "11111111111".substring(0,needAddNum).split("");
            }
        },
        // 待封装优化代码段 liangpengyu
        watchSize_s() {
            let _this = this;
            let erd = elementResizeDetectorMaker();
            erd.listenTo(this.$refs.bots_s, (element) => {
                _this.$nextTick(() => {
                    if (_this.listLen_s !== 0) {
                        _this.addEmptyEl_s(_this.listLen_s);
                    }
                })
            });
        },
        // 待封装优化代码段 liangpengyu
        addEmptyEl_s(listLen_s){
            let outerW = this.$refs.bots_s.offsetWidth; // 容器宽度
            let cellW = this.$refs.firstPubSkill[0].offsetWidth; // 每个cell宽度
            let evNum = Math.floor(outerW/cellW); // 每行个数
            let needAddNum = (evNum - ((listLen_s)%evNum)) === evNum?0:(evNum - ((listLen_s)%evNum)); // 最后一行需要补空意图数
            this.needAddNum_s = needAddNum;
            
            this.needAddNumArr_s = "11111111111".substring(0,needAddNum).split("");
        },
        // 待封装优化代码段 liangpengyu
        watchSize_p() {
            let _this = this;
            let erd = elementResizeDetectorMaker();
            erd.listenTo(this.$refs.bots_p, (element) => {
                _this.$nextTick(() => {
                    if (_this.listLen_p !== 0) {
                        _this.addEmptyEl_p(_this.listLen_p);
                    }
                })
            });
        },
        // 待封装优化代码段 liangpengyu
        addEmptyEl_p(listLen_p){
            let outerW = this.$refs.bots_p.offsetWidth; // 容器宽度
            let cellW = this.$refs.firstSelfSkill[0].offsetWidth; // 每个cell宽度
            let evNum = Math.floor(outerW/cellW); // 每行个数
            let needAddNum = (evNum - ((listLen_p)%evNum)) === evNum?0:(evNum - ((listLen_p)%evNum)); // 最后一行需要补空意图数
            this.needAddNum_p = needAddNum;
            this.needAddNumArr_p = "11111111111".substring(0,needAddNum).split("");
        },
        //
        chooseBot(item, index) {
            if (item.used) {
                this.$message.error(
                    "该机器人已使用了该知识库，不允许添加同一个知识库"
                );
            } else {
            }
        },
        // 选中机器人头像
        chooseBotIcon(item, index) {
            this.BotActiveObj = item;
            this.botIconIndex = index;
        },
        // 获取机器人默认头像
        getBotDefaultIcon() {
            this.FetchGet(this.requestUrl.bot.getBotProfilePhoto).then(
                (res) => {
                    if (res.code === "0") {
                        this.iconList = res.data || [];
                        if(!this.BotActiveObj.botProfilePhoto){
                            this.BotActiveObj =  this.iconList[0];
                        } else {
                            let flag = false;
                            this.iconList.forEach((item,index) => {
                                if(item.botProfilePhoto === this.BotActiveObj.botProfilePhoto){
                                    flag = true;
                                } 
                            })
                            if(!flag){
                                this.BotActiveObj =  this.iconList[0];
                            }
                        }
                       

                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        // 删除机器人头像
        delBotIcon(item) {
            this.FetchDelete(
                this.requestUrl.bot.deleteBotProfilePhoto,
                item.id
            ).then((res) => {
                if (res.code === "0") {
                    this.getBotDefaultIcon();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        handleAvatarSuccess(res, file) {
            this.FetchPost(this.requestUrl.bot.saveBotProfilePhoto, {
                botProfilePhoto: res.data,
                theDefault: false,
                order: 0,
            }).then((res) => {
                if (res.code === "0") {
                    this.getBotDefaultIcon();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        beforeAvatarUpload(file) {
            if (file) {
                if (!getFileSuffix(file.name, ["png", "jpg", "jpeg"])) {
                    this.$message.error("请上传png,jpg,jpeg格式的图片");
                    return false;
                }
                //判断上传的视频大小
                if (file.size / 1024 / 1024 > 2) {
                    this.$message.error("文件大小不能超过2Mb");
                    return false;
                }
            }
        },
        switchTab(type) {
            console.log(type);
            let botVersion = localStorage.getItem('_bot_version');
            if (type === 'private' && botVersion == 2) {
                this.tipIndex = 0;
                this.versionTipDialog = true;
                return false;
            } else {
                this.kbbActiveName = type;
            }
        },
       
        kbbTabClickBefore(activeName, oldActiveName){
            let botVersion = localStorage.getItem('_bot_version');
            if (activeName == 'private' && oldActiveName == 'official' && botVersion == 2) {
                this.tipIndex = 0;
                this.versionTipDialog = true;
                return false;
            }
        },
        getLimitMsg(){
            this.FetchGet(
                this.requestUrl.bot.selectLimitMsg + '?uid=' + localStorage.getItem("_uid")
                ).then((res) => {
                    if (res.code === "0") {
                        this.limitUse = !res.data.canCreateBot;
                        localStorage.setItem("_limitMsg", JSON.stringify(res.data));
                    }
                });
            
            this.FetchGet(
                this.requestUrl.bot.getUserCompanyById + '?userId=' + localStorage.getItem("_uid")
                ).then((res) => {
                    if (res.code === "0") {
                        res.data.modules.forEach(item => {
                            if (item.moduleCode === "OPEN") {
                                localStorage.setItem("_bot_version", item.config.moduleVersion.versionType);
                                localStorage.setItem("_bot_version_num", item.config.moduleVersion.botNumLimit);
                                localStorage.setItem("_bot_version_intent_num", item.config.moduleVersion.intentNumLimit);
                                // localStorage.setItem("_bot_version", 2);
                            }
                        })
                    }
                });
            
        },
        addBotType(val){
            if (!val && this.limitUse) {
                this.isAddExit = true;
                this.useTemplateDialogVisible = false;
                this.tipIndex = 3;
                this.versionTipDialog = true;
            }
        },
        
        editBotSt() {
            this.editBotStVisible = true;
            this.$refs.editBotSt.getBotList();
        },
        closeEditBotSt() {
            this.editBotStVisible = false;
        },
        saveEditBotSt(list) {
            let param = [];
            list.forEach((bot, index) => {
                param.push({
                    id: bot.id,
                    botOrder: index + 1,
                });
            });
            this.FetchPut(this.requestUrl.bot.updateUserBotOrder, "", {
                botOrderList: param,
            }).then((res) => {
                if (res.code === "0") {
                    this.getBotList();
                    this.editBotStVisible = false;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        changeExpend(index, val) {
            this.botKnList[index].isExpend = val;
            this.botKnList = [...this.botKnList];
        },
        closeKnTreeBox() {
            this.getSkillTree();
            this.getSkillTemplateUser();
        },
        // 管理知识库模板
        managePrivate() {
            let arr = JSON.parse(JSON.stringify(this.botKnList))
            this.checkedKnInfos.forEach((item) => {
               arr.forEach((cell) => {
                    if (item.botId == cell.id) {
                        cell.children.forEach((skill) => {
                            if (item.skillId == skill.id) {
                                skill.isChecked = true;
                            }
                        });
                    }
                });
            });
            arr.forEach((item,index) => {
                let list = [];
                if(item.children && item.children.length > 0){
                    item.children.forEach((skill) => {
                        if(skill.name !== '特殊规则' && !skill.isChecked){
                            list.push(skill.isTemplateCreate+'');
                        }
                    })
                    if(list.length > 0){
                        if(list.indexOf('false') > -1){
                            item.botIsDisabled = false;
                        } else {
                            item.botIsDisabled = true;
                        }
                    } else {
                        item.children = [];
                    }
                }
               
            })
            this.botKnList = [...arr];
            this.manageKNPVisible = true;
        },
        // 选择机器人下所有知识库作为知识库模板
        checkedAll(index) {
            this.botKnList[index].children.forEach((item) => {
                item.botIsDisabled = true;
                !item.isTemplateCreate &&
                    item.name != "特殊规则" &&
                    !item.isChecked &&
                    this.checkedKnInfos.push({
                        botName: this.botKnList[index].name,
                        botId: this.botKnList[index].id,
                        skillName: item.name,
                        skillId: item.id,
                        isTemplateCreate:item.isTemplateCreate
                    });
            });

            this.botKnList[index].children = [...[]];
            this.botKnList[index].checkedAll = true;
        },
        // 选择单个知识库模板
        checkedSing(index, knCellIndex,item,knCellItem) {
            console.log(index, knCellIndex,item,knCellItem);
            this.checkedKnInfos.push({
                botName: item.name,
                botId: item.id,
                skillId: knCellItem.id,
                skillName:knCellItem.name,
                isTemplateCreate:knCellItem.isTemplateCreate
            });
            this.$nextTick(() => {
                item.children.splice(knCellIndex, 1);
                knCellItem.isChecked = true;
                let list = []
                if( item.children &&  item.children.length > 0){
                     item.children.forEach((v,i) => {
                        if(v.name !== '特殊规则' && !v.isChecked){
                            list.push(v.isTemplateCreate + '');
                        }
                    })
                    if(list.length > 0){
                        if(list.indexOf('false') > -1){
                            item.botIsDisabled = false;
                        } else {
                            item.botIsDisabled = true;
                        }
                    } else {
                        item.children = [];
                    }
                }
                console.log(item,'itemitem');
               
            });
        },
        // 删除知识库模板选择
        closeKn(knInfo, index) {
            this.botKnList.forEach((cell) => {
                if (cell.id == knInfo.botId) {
                    cell.children.push({
                        id: knInfo.skillId,
                        name: knInfo.skillName,
                        isChecked: false,
                        isTemplateCreate:knInfo.isTemplateCreate || false
                    });
                    cell.checkedAll = false;
                    cell.botIsDisabled = false;
                }
            });
            this.botKnList = [...this.botKnList];
            this.$nextTick(() => {
                this.checkedKnInfos.splice(index, 1);
            });
        },
        // 提交知识库管理模板修改
        submitKnP() {
            let checkedKnInfosParam = JSON.parse(
                JSON.stringify(this.checkedKnInfos)
            );
            this.FetchPost(
                this.requestUrl.skill.skillTemplateConfig,
                checkedKnInfosParam
            ).then((res) => {
                if (res.code === "0") {
                    this.getPubList();
                    this.getSkillTree();
                    this.manageKNPVisible = false;
                } else {
                    this.$message.error(res.message);
                }
            });
        },

        firstGuide() {
            this.centerDialogVisible = true;
            this.guideImageUrl =
                "https://guoranbot-static.oss-cn-beijing.aliyuncs.com/image/startup1.png";
        },
        //第一次登陆引导
        firstGuideNext() {
            if (this.guideImageClickNum < 11) {
                this.guideImageClickNum++;
                this.guideImageUrl =
                    "https://guoranbot-static.oss-cn-beijing.aliyuncs.com/image/startup" +
                    this.guideImageClickNum +
                    ".png";
            } else if ((this.guideImageClickNum = 11)) {
                this.centerDialogVisible = false;
                this.guideImageClickNum = 1;
                this.driver = new Driver({
                    element: ".chooseHelp",
                    className: "scoped-class", // className to wrap driver.js popover
                    stageBackground: "rgb(51, 51, 51)", //突出显示元素的背景颜色
                    animate: true, // Animate while changing highlighted element
                    opacity: 0.75, // Background opacity (0 means only popovers and without overlay)
                    padding: 10, // Distance of element from around the edges
                    allowClose: true, // Whether clicking on overlay should close or not
                    overlayClickNext: true, // Should it move to next step on overlay click
                    doneBtnText: "完成", // Text on the final button
                    closeBtnText: "关闭", // Text on the close button for this step
                    nextBtnText: "下一步", // Next button text for this step
                    prevBtnText: "上一步", // Previous button text for this step
                    // Called when moving to next step on any step
                });
                this.driver.defineSteps(steps);
                this.driver.start();
                //this.driver = new Driver()
                //首次登陆系统出现引导
            }
        },
        firstGuideBack() {
            if (this.guideImageClickNum > 0) {
                this.guideImageClickNum--;
                this.guideImageUrl =
                    "https://guoranbot-static.oss-cn-beijing.aliyuncs.com/image/startup" +
                    this.guideImageClickNum +
                    ".png";
            }
        },
        handleClose() {
            this.centerDialogVisible = false;
            this.guideImageClickNum = 1;
        },
        // 点击新建机器人
        addBot(add) {
            this.getBotDefaultIcon();
            this.botIconIndex = 0;
            this.BotActiveObj = {};
            // 限制使用新增机器人
            if (this.limitUse) {
                this.tipIndex = 3;
                this.versionTipDialog = true;
            } else {
                this.activeBotDiaHandleTitle = botDiaHandleTitle.add;
                this.dialogVisible = true;
                this.botName = "";
                this.botDes = "";
                if (add) {
                    this.isAdd = true;
                } else {
                    this.isAdd = false;
                }
            }
        },
        /**
         * 点击选择机器人模板
         * @method selectUseTemplateType
         * @param {Object} templateInfo {refApiKey,refSkillId,name,description} 模版信息
         * @return {}
         */
        selectUseTemplateType(templateInfo) {
            this.templateInfo = templateInfo;
            this.useTemplateDialogVisible = true;
            this.isAddBotId = '';
            let targetApiKeys = [];
            this.botList.forEach((item, index) => {
                targetApiKeys.push(item.apiKey);
            });
            this.FetchPost(
                this.requestUrl.bot.selectBotRelatedSkill +
                    "/" +
                    templateInfo.skillId,
                targetApiKeys
            ).then((res) => {
                if (res.code === "0") {
                    this.botList.forEach((item, index) => {
                        if (res.data && res.data.length > 0) {
                            if (res.data.indexOf(item.apiKey) !== -1) {
                                item.used = true;
                            } else {
                                item.used = false;
                            }
                        } else {
                            item.used = false;
                        }
                    });
                } else {
                    this.$message.error(res.message);
                }
                this.loading = false;
            });
        },
        /**
         * 确认使用机器人模板方式
         * @method confirmUseTemplate
         * @param {}
         * @return {}
         */
        confirmUseTemplate() {
            if (this.isAddExit) {
                if (this.isAddBotId !== "") {
                    this.createRefBot(false, this.isAddBotId);
                    this.useTemplateDialogVisible = false;
                } else {
                    this.$message.warning("请选择添加到哪个机器人");
                }
            } else {
                this.useTemplateDialogVisible = false;
                this.createRefBot(true);
            }
        },
        //根据模板创建机器人
        createRefBot(isNewCreate, targetApiKey) {
            let refApiKey = this.templateInfo.apiKey,
                refSkillId = this.templateInfo.skillId,
                name = this.templateInfo.name,
                description =
                    this.templateInfo.description == null
                        ? ""
                        : this.templateInfo.description;

            if (refApiKey) {
                let param = {
                    refApiKey: refApiKey.trim(),
                    refSkillId: refSkillId,
                    name: name.trim(),
                    description: description.trim(),
                    isNewCreate: isNewCreate,
                };
                if (!isNewCreate) {
                    param.targetApiKey = targetApiKey;
                }
                // 提交新增机器人信息
                this.FetchPost(this.requestUrl.bot.createRefBot, param).then(
                    (res) => {
                        if (res.code === "0") {
                            this.getBotList();
                            this.$message.success("您的机器人创建成功！");
                            this.getLimitMsg();
                            let newBotId = this.newBotId;
                            let newBotName = this.newBotName;
                            newBotId = res.data;
                            let that = this;
                            //根据botId获取BotName
                            this.FetchGet(
                                this.requestUrl.bot.getBotInfo + "/" + newBotId
                            ).then((res) => {
                                if (res.code === "0") {
                                    if (res.data.list != null) {
                                        this.botList = [...res.data.list];
                                    }
                                    that.newBotName = res.data.name;
                                    this.confirmGotoIntent(
                                        newBotId,
                                        that.newBotName,
                                        refApiKey
                                    );
                                } else {
                                    this.$message.error(res.message);
                                }
                                this.loading = false;
                            });
                        } else {
                            this.$message.error(res.message);
                        }
                    }
                );
            } else {
                this.$message.error("根据模板创建机器人暂时不可用哦");
            }
        },
        //确认是否跳转至新建意图页面
        confirmGotoIntent(newBotId, newBotName, refApiKey) {
            this.$confirm("已根据模板创建机器人，是否立即配置?", {
                confirmButtonText: "立即前往",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    // 新增机器人跳转到设置
                    this.$router.push({
                        name: "botIntent",
                        query: {
                            id: newBotId,
                            name: newBotName.trim(),
                            refApiKey: refApiKey,
                        },
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消立即配置",
                    });
                });
        },
        // 点击操作机器人
        botHandleCommand(command,item) {
            if (command.type === 1) {
                this.BotActiveObj = item;
                this.getBotDefaultIcon();
                this.activeBotDiaHandleTitle = botDiaHandleTitle.edit;
                this.dialogVisible = true;
                this.isAdd = false;
                this.botName = this.botList[command.index].name;
                this.botDes = this.botList[command.index].description;
                this.activeEditId = this.botList[command.index].id;
            }
            if (command.type === 2) {
                this.$confirm("确认删除机器人？")
                    .then((_) => {
                        // 删除机器人
                        this.FetchDelete(
                            this.requestUrl.bot.deleteBot,
                            this.botList[command.index].id
                        ).then((res) => {
                            if (res.code === "0") {
                                this.$message.success("删除成功！");
                                this.getBotList();
                            } else {
                                this.$message.error(res.message);
                            }
                        });
                    })
                    .catch((_) => {});
            }
        },
        // 提交新建机器人
        submitAddBot(type) {
            if (Boolean(String(this.botName.trim()).length)) {
                let botProfilePhotoUrl = "";
                this.iconList.forEach((item, index) => {
                    if (this.BotActiveObj.botProfilePhoto === item.botProfilePhoto) {
                        botProfilePhotoUrl = item.botProfilePhoto;
                    }
                });
                // 提交新增机器人信息
                type === 2 &&
                    this.FetchPost(
                        this.requestUrl.bot.createBot +
                            "?botProfilePhotoUrl=" +
                            botProfilePhotoUrl,
                        {
                            name: this.botName.trim(),
                            description: this.botDes.trim(),
                            createGreetingSkill: this.botNeedChatSkill,
                        }
                    ).then((res) => {
                        if (res.code === "0") {
                            this.getBotList();
                            this.$message.success("您的机器人创建成功！");
                            this.dialogVisible = false;
                            this.getLimitMsg();
                            // 新增机器人跳转到设置
                            this.$router.push({
                                name: "botIntent",
                                query: {
                                    id: res.data,
                                    name: this.botName.trim(),
                                },
                            });
                        } else {
                            this.$message.error(res.message);
                        }
                    });

                // 提交更新机器人信息
                type === 1 &&
                    this.FetchPut(
                        this.requestUrl.bot.updateBot,
                        this.activeEditId,
                        {
                            name: this.botName.trim(),
                            description: this.botDes.trim(),
                            botProfilePhoto: botProfilePhotoUrl,
                        }
                    ).then((res) => {
                        if (res.code === "0") {
                            this.getBotList();
                            this.$message.success("您的机器人信息更新成功！");
                            this.dialogVisible = false;
                        } else {
                            this.$message.error(res.message);
                        }
                    });
            } else {
                this.$message.error("请为您的机器人取个名字吧！");
            }
        },

        // 跳转到机器人意图配置列表
        gotoBotIntent(botId, botName, refApiKey, from,item) {
            if (this.RP_Visible("OPEN_SKILL")) {
                console.log(item.setting.keywordRecommendHasNew,111111);
                sessionStorage.setItem('keywordRecommendHasNew',item.setting.keywordRecommendHasNew);
                this.$router.push({
                    name: "botIntent",
                    query: {
                        id: botId,
                        name: botName,
                        refApiKey: refApiKey,
                        from: from,
                        tabIndex: "skill",
                    },
                });
            } else {
                if (this.RP_Visible("OPEN_PROCESS")) {
                    this.$router.push({
                        name: "chatFlow",
                        query: {
                            id: botId,
                            name: botName,
                            refApiKey: refApiKey,
                            from: from,
                            tabIndex: "second",
                        },
                    });
                } else {
                    this.$router.push({
                        name: "botIntent",
                        query: {
                            id: botId,
                            name: botName,
                            refApiKey: refApiKey,
                            from: from,
                            tabIndex: "bot-setting",
                        },
                    });
                }
            }
        },
        // 获取机器人列表
        getBotList() {
            this.FetchGet(this.requestUrl.bot.getBotList, {
                page: 1,
                size: 100,
            }).then((res) => {
                if (res.code === "0") {
                    this.botList = res.data.list;
                    this.$nextTick(() => {
                        this.watchSize();
                    })
                } else {
                    this.$message.error(res.message);
                }
                this.loading = false;
            });
        },

        getPubList() {
            this.FetchGet(this.requestUrl.skill.templateSkillListAll, {}).then(
                (res) => {
                    if (res.code === "0") {
                        this.pubSkillList = res.data.defaultTemplates;
                        this.userTemplates = res.data.userTemplates;
                        this.$forceUpdate();
                        this.$nextTick(() => {
                            this.watchSize_s();
                            this.watchSize_p();
                        })
                    }
                }
            );
        },
       // 获取知识库树
        getSkillTree() {
            this.FetchGet(this.requestUrl.skill.skillTree, {}).then((res) => {
                if (res.code === "0") {
                    this.botKnList = res.data;
                    this.botKnList.forEach((item) => {
                        item.botIsDisabled = false;
                        item.checkedAll = false;
                        item.isExpend = true;
                        item.children =
                            item.children == null ? [] : item.children;
                        item.children.forEach((cell) => {
                            cell.isChecked = false;
                        });
                    });
                    this.botKnList = [...this.botKnList];
                }
            });
           
        },
        // 查询用户的知识库模板，只包含用户有权限配置的
        getSkillTemplateUser() {
            this.FetchGet(this.requestUrl.skill.skillTemplateUser, {}).then(
                (res) => {
                    if (res.code === "0") {
                        this.checkedKnInfos = res.data ? res.data : [];
                    }
                }
            );
        },

        //初次登陆引导层
        guide() {
            this.loginSuccessTimes = localStorage.getItem("loginSuccessTimes");
            let guided = localStorage.getItem("guided");
            if (this.loginSuccessTimes === "1" && guided == null) {
                this.firstGuide();
                localStorage.setItem("guided", "true");
            }
        },
    },
    watch: {
        botList: function(newValue, oldValue){
            this.$nextTick(() => {
                this.listLen = newValue.length;
                this.addEmptyEl(newValue.length);
            })
           
            
        },
        pubSkillList: function(newValue, oldValue){
            this.$nextTick(() => {
                this.listLen_s = newValue.length;
                this.addEmptyEl_s(newValue.length);
            })
           
            
        },

        userTemplates: function(newValue, oldValue){
            this.$nextTick(() => {
                this.listLen_p = newValue.length;
                this.addEmptyEl_p(newValue.length);
            })
        },
    },
    mounted() {
        this.getPubList();
        this.getBotList();
        this.getSkillTree();
        this.getSkillTemplateUser();
        this.accountType = localStorage.getItem("accountType");
        this.guide();
        this.getLimitMsg();
        this.$eventBus.$on("test-window-visible", (visible) => {
            this.isShowTest = visible;
            if(this.isShowTest){
                this.isShowHeathly = false;
            }
            setTimeout(() => {
                // this.addEmptyEl(this.listLen);
                // this.addEmptyEl(this.listLen_s);
                // this.addEmptyEl(this.listLen_p);
                this.watchSize();
                this.watchSize_s();
                this.watchSize_p();
            }, 300)
        });
        this.isShowTest = sessionStorage.getItem('isOpenTestDrawer') == 'true' ? true : false;
        this.isShowHeathly = sessionStorage.getItem('isOpenHeathlyDrawer') == 'true' ? true : false;
        this.$eventBus.$on("bot-heathly-visible", (visible) => {
            this.isShowHeathly = visible.flag;
            if(this.isShowHeathly){
                this.isShowTest = false;
            }
            setTimeout(() => {
                // this.addEmptyEl(this.listLen);
                // this.addEmptyEl(this.listLen_s);
                // this.addEmptyEl(this.listLen_p);
                this.watchSize();
                this.watchSize_s();
                this.watchSize_p();
            }, 300)
        });
        this.$nextTick(() => {
            setTimeout(() => {
                this.watchSize();
                this.watchSize_s();
            }, 100)
            setTimeout(() => {
                this.watchSize_p();
            }, 100)
        })
        sessionStorage.removeItem('_activeSkillList');
        sessionStorage.removeItem('_activeClassList');
        sessionStorage.removeItem('_activeSkill');
        sessionStorage.removeItem('_activeClass');
        sessionStorage.removeItem('IntentIsRet');
        this.getCompanyIsOld();
    },
};
</script>
<style lang="less" scope>
@import "./../../assets/less/botIndex/botIndex.less";
#botIndex {
    .botLimitUse {
        opacity: 0.5;
    }
    .first-guide {
        position: absolute;
        top: 10px;
        right: 40px;
        cursor: pointer;
        display: flex;
        align-items: center;
        em {
            color: #366aff;
        }
        img {
            height: 25px;
            margin-top: -5px;
        }
    }
    .edit-bot-d {
        margin-left: 5px;
        color: #616161;
    }
    .el-icon-edit-sort {
        display: inline-block;
        // height: 23px;
        // line-height: 23px;
        cursor: pointer;
        font-size: 12px;
        color: @system_bgc_red;
    }
    .skip-button {
        color: #cecece;
        cursor: pointer;
        position: absolute;
        right: 30px;
        bottom: 30px;
    }
   
}
</style>
